<script>
import ApiService from "@/services/api.service";

export default {
  name: "News",
  data() {
    return {
      title: "News",
      table: {
        sortBy: "recordDate",
        fields: [
          { key: "recordDate", label: "Publish Date" },
          { key: "title", class: "text-left" },
          { key: "newsGroup", class: "text-right" },
          { key: "isFeatured", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        pageListOptions: [10, 20, 50, 100],
        paging: {
          curPage: 1,
          perPage: 10,
          totalCount: 1
        },
        filter: null
      },

      parameter: {
        newsGroup: []
      },

      news: {
        code: null,
        title: null,
        newsGroup: null,
        status: null,
        rank: null,
        content: null
      },
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    editMode: function () {
      return this.news.code != null;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("news")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async deleteNews(code) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: code
        };
        ApiService.delete("news", qs)
          .then(() => {
            this.showToast("success", "Delete Sucessfull");
            this.loadTable();
          })
          .catch(error => {
            this.showToast("error", error.message);
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div id="mount-point"></div>
      <div class="d-flex mb-2">
        <router-link :to="{ name: 'cms-news-detail', params: { mode: 'create' } }">
          <b-btn variant="outline-primary">Create</b-btn>
        </router-link>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-filtering
        sortDirection="desc"
        sortDesc="true"
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #cell(newsGroup)="data">
          <div>
            {{ data.item.newsGroupDesc }}
          </div>
        </template>
        <template #cell(isFeatured)="data">
          <div>
            <b-badge :variant="data.item.isFeatured ? 'success' : 'secondary'" class="text-capitalize">
              {{ data.item.isFeatured ? "Featured" : "Not Featured" }}
            </b-badge>
          </div>
        </template>
        <template #cell(recordDate)="data">
          <div>
            {{ data.item.recordDate | dateTime }}
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <router-link :to="{ name: 'cms-news-detail', params: { mode: 'edit', newsCode: data.item.code } }">
              <b-btn class="btn btn-sm btn-wide btn-warning mr-1">Edit</b-btn>
            </router-link>
            <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="deleteNews(data.item.code)">Delete</b-btn>
          </div>
        </template>
      </b-table>

      <div class="d-flex mt-2">
        <span class="mr-auto"
          >Showing of <span class="font-weight-bold">{{ rowCount }}</span> of
          <span class="font-weight-bold">{{ table.paging.totalCount }}</span></span
        >
        <b-pagination
          v-model="table.paging.curPage"
          class="ml-auto"
          :total-rows="rowCount"
          :per-page="table.paging.perPage"
          align="center"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>
